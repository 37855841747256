<template>
  <div class="col-12 col-md-8 mx-auto">
    <div class="row">
      <div class="col">
        <h1>Add Email</h1>
      </div>
    </div>
    <div class="card theme-card shadow-card">
      <div class="card-body">
        <form @submit="addEmail" validate>
          <input-control v-model="email" :required="true" control="email"
            >Email</input-control
          >
          <save :saving="emails.isSaving">Save Email</save>
          <button
            v-if="src"
            class="btn btn-link ml-2"
            @click.prevent="navigateBack"
          >
            Cancel
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      patients: (state) => state.patients,
      patient: (state) => state.patients.patient,
      emails: (state) => state.patients.emails,
    }),
    src: function () {
      return this.$route.query.src;
    },
  },
  data() {
    return {
      email: null,
    };
  },
  mounted() {
    if (this.patient == null) {
      this.$router.back();
    }
  },
  methods: {
    addEmail: function (e) {
      e.preventDefault();
      let data = {
        patient_id: this.patient.id,
        user_id: this.patient.user_id,
        email: this.email,
      };
      this.$store.dispatch("patients/emails/create", data);
    },
    navigateBack: function () {
      this.$router.go(-1);
    },
  },
};
</script>
